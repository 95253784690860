<template>
  <div class="bigbox">
    <Nav />
    <div class="head" @click="btn">
      <div>
        <img
          style="margin-left:20px;height:60pt;width:60pt; border-radius:50% ;"
          :src="this.headimg"
          alt
        />
      </div>

      <div class="title">
        修改信息
        <img style="width:10px;height:15px" src="../assets/images/right.png" alt />
      </div>
    </div>
    <div class="main">
      <div class="box">
        <div>用户名</div>
        <div>
          <span>{{this.name}}</span>
          <!-- <img style="width:10px;height:15px"  src="../assets/images/right.png" alt /> -->
        </div>
      </div>
      <div class="box">
        <div>性别</div>
        <span style="margin-left:20%">{{this.sex}}</span>
      </div>
      <div class="box" @click="phon">
        <div>修改绑定手机</div>
        <span style="margin-left:20%">{{this.phone}}</span>
        <div>
          <img style="width:10px;height:15px" src="../assets/images/right.png" alt />
        </div>
      </div>
    </div>
    <div class="foot" @click="input">
      <div class="box">
        <div>兑换优惠券</div>
        <div style="color:#aaa ;margin-right:20px">
        
          <img style="width:10px;height:15px;" src="../assets/images/right.png" alt />
        </div>
      </div>
    </div>
    <van-dialog  :before-close="beforeClose" v-model="show" title="兑换券" show-cancel-button>
      <input
        v-model="code"
        class="qm"
        style="border:#eee solid 1px;margin-left:10px;font-size:12px;height:30px;width:90%;"
        type="text"
        placeholder="请输入兑换券码"
      />
    </van-dialog>
    <!-- <div class="foot">
      <div class="box">
        <div>实名认证</div>
        <div style="color:#aaa ;margin-right:20px">已认证 <img style="width:10px;height:15px;" src="../assets/images/right.png" alt /></div>
      </div>
    </div>-->
    <div class="btnbox" @click="getout">
      <button class="btn">切换登陆账号</button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { code_api } from '../http/api/service'
import { newinfo_api } from '../http/api/certification'

import Nav from '@/components/Nav.vue'
export default {
  components: { Nav },
  name: 'TaxiH5Core',

  data() {
    return {
      code: '',
      show: false,
      phone: '',
      name: '',
      sex: '',
      headimg: '',
    }
  },
created(){
    var img = JSON.parse(sessionStorage.getItem('token'))
     this.headimg=img.headImg
     //console.log(this.headimg);
  this.info()
},
  mounted() {
    
   
  },

  methods: {
   async info(){
      let res=await newinfo_api({

      })
      if(res.code==200){
        if(res.data.headImg==''){
          this.headimg='https://img.yzcdn.cn/vant/cat.jpeg'
        }else{
             this.headimg=res.data.headImg
        }
   
       this.phone = res.data.phone
    this.name = res.data.userName
     this.sex = res.data.sex
    if (this.sex == 2) {
      this.sex = '女'
    } else {
      this.sex = '男'
    }
      }
    
    },
   async codeipt(done) {
       var token = JSON.parse(sessionStorage.getItem('token')).token
      let res = await code_api({
        token: token, //用户token
        code: this.code, //兑换码code
      })
      console.log(res);
      if(res.code==200){     
        Toast(res.message);
          this.show = false
          this.code=''
          done();
      }else{
        this.code=''
         done(false); 
      }
    
    },
    //输入券码
    input() {
      this.show = true
    },
    beforeClose(e,done) {
      if (e == 'confirm') {
   this.codeipt(done)
  
         
      } else {
        this.show = false
         done()
      }
    },
    //退出登录
    getout() {
      localStorage.clear()
      sessionStorage.clear()
      this.$router.push({
        path: '/',
      })
    },
    //修改手机号
    phon() {
      this.$router.push({
        path: '/phon',
      })
    },
    btn() {
      this.$router.push({
        path: '/modify',
      })
    },
  },
}
</script>

<style scoped  lang="scss">
.bigbox {
  background-color: #eee;
  width: 100%;
  height: 100vh;
  .head {
    margin-top: 20px;
    display: flex;
    height: 90pt;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #999;
      font-size: 14pt;
      margin-right: 20px;
    }
  }
  .main {
    margin-top: 20px;
    width: 100%;
    height: 150px;
    background-color: #fff;
    display: inline-block;

    .box {
      display: flex;
      justify-content: space-between;
      color: #333;
      font-size: 16px;
      margin: 0 20px;
      height: 50px;
      align-items: center;
      border-bottom: solid 1px #eee;
    }
  }
  .foot {
    height: 50px;
    background-color: #fff;
    margin-top: 20px;

    .box {
      display: flex;
      justify-content: space-between;
      color: #333;
      font-size: 16px;
      margin-left: 20px;
      line-height: 50px;
    }
  }
  .btnbox {
    .btn {
      width: 90%;
      height: 40px;
      background-color: #eb4541;
      border: 0;
      color: #fff;
      border-radius: 30px;
      margin-left: 5%;
      margin-top: 20%;
      font-size: 16px;
    }
  }
  .dialog{
     margin: 20px 0;
  }
  .qm{
    margin: 20px 0;
  }
}
</style>